import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThatDayComponent } from './that-day/that-day.component';
import { ThisWeekComponent } from './this-week/this-week.component';
import { ThatMonthComponent } from './that-month/that-month.component';
import { ThatYearComponent } from './that-year/that-year.component';
import { ThisAgeComponent } from './this-age/this-age.component';
import { SummaryComponent } from './summary/summary.component';
import { AddMoneyComponent } from './add-money/add-money.component';
import { LoginComponent } from './login/login.component';
import { SettingComponent } from './setting/setting.component';
import { SearchRemarkComponent } from './search-remark/search-remark.component';
import { SearchPriceComponent } from './search-price/search-price.component';

const routes: Routes = [
  { path: '', component: ThatDayComponent },
  { path: 'ThatDay', component: ThatDayComponent },
  { path: 'ThatDay/:udate', component: ThatDayComponent },
  { path: 'ThisWeek', component: ThisWeekComponent },
  { path: 'ThatMonth', component: ThatMonthComponent },
  { path: 'ThatMonth/:udate', component: ThatMonthComponent },
  { path: 'ThatYear', component: ThatYearComponent },
  { path: 'ThatYear/:udate', component: ThatYearComponent },
  { path: 'ThisAge', component: ThisAgeComponent },
  { path: 'Summary', component: SummaryComponent },
  { path: 'AddMoney', component: AddMoneyComponent },
  { path: 'AddMoney/:money_id', component: AddMoneyComponent },
  { path: 'Login', component: LoginComponent },
  { path: 'Setting', component: SettingComponent },
  { path: 'SearchRemark', component: SearchRemarkComponent },
  { path: 'SearchPrice', component: SearchPriceComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
