import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpService } from '../http.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  login_name: String = "";
  password: String = "";

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService) { }

  submit() {

    console.log(this.login_name);
    console.log(this.password);

    let errStr: String = "";

    if (this.login_name.trim() == "") {
      errStr += "請輸入登入名稱!\n";
    }

    if (this.password.trim() == "") {
      errStr += "請輸入密碼!";
    }

    if (errStr.length > 0) {
      alert(errStr);
      return;
    }


    document.getElementById("pnLoading").style.display = "block";

    this.http.checkLogin(this.login_name, this.password)
      .subscribe(res => {

        if (res.code === 200) {
          localStorage.setItem('login_name', res.login_name);
          localStorage.setItem('access_token', res.access_token);
          localStorage.setItem('refresh_token', res.refresh_token);
          localStorage.setItem("scList", JSON.stringify(res.scList));
          window.location.href = '/ThatDay';
        }
        else {
          alert('登入失敗!');
        }

        document.getElementById("pnLoading").style.display = "none";
      },
        err => {
          alert(err.error)
          document.getElementById("pnLoading").style.display = "none";
        }
      );

  }

  ngOnInit(): void {
  }

}
