import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { HttpService } from '../http.service';
import * as moment from 'moment';



@Component({
  selector: 'app-that-day',
  templateUrl: './that-day.component.html',
  styleUrls: ['./that-day.component.css']
})
export class ThatDayComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService) { }

  udate: string = "";

  sum: number = 0;

  money: Object;

  clickRow(money_id) {
    this.router.navigate(['/AddMoney/'], { queryParams: { money_id: money_id } });
  }

  ngOnInit(): void {


    this.route.queryParams.subscribe((value) => {
      if (value['udate'] == null) {
        this.router.navigate(['/ThatDay'], { queryParams: { udate: moment().format("yyyy-MM-DD") } });
        return;
      }
      else {
        this.udate = value['udate'];

        document.getElementById("pnLoading").style.display = "block";

        this.http.getThatDay(this.udate).subscribe(res => {

          if (res.code === 200) {
            this.money = res.money;

            res.money.map((mn, i) => {
              this.sum += parseInt(mn.price);
            });
          }
          else if (res.code == 401) {
            this.http.logout();
          }
          else {
            alert('系統錯誤');
          }

          document.getElementById("pnLoading").style.display = "none";

        },
          err => {
            alert(err.error)
            document.getElementById("pnLoading").style.display = "none";
          });
      }
    });

  }

}
