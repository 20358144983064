<h4><b>總覽</b></h4><br>
<table class="table table-striped">
    <tbody>
        <tr>
            <td width="50%"><b>每月預算:</b></td>
            <td width="50%">${{budget}}</td>
        </tr>
        <tr>
            <td><b>本月支出:</b></td>
            <td [ngClass]="budget >= this_month ? 'text-success' : 'text-danger'">${{this_month}}</td>
        </tr>
        <tr>
            <td><b>本月盈餘:</b></td>
            <td [ngClass]="hidden_average >= average ? 'text-success' : 'text-danger'">${{surplus}}</td>
        </tr>
        <tr>
            <td><b>平均每日:</b></td>
            <td [ngClass]="surplus>=0 ? 'text-success' : 'text-danger'">${{average}}</td>
        </tr>
        <tr>
            <td><b>本日支出:</b></td>
            <td [ngClass]="hidden_average >= this_day ? 'text-success' : 'text-danger'">${{this_day}}</td>
        </tr>
    </tbody>
</table>
<br />


<table class="table table-striped table-sm">
    <thead class="table-warning">
        <tr>
            <th width="50%">備註</th>
            <th width="50%">總共</th>
        </tr>

    </thead>
    <tbody>
        <tr *ngFor="let remark of remark_list">
            <td><a class="text-primary" (click)="clickRemark(remark.remark)">{{remark.remark}}</a> </td>
            <td>${{remark.sum}}</td>
        </tr>
    </tbody>
</table>