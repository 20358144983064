import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { HttpService } from '../http.service';
import * as moment from 'moment';

@Component({
  selector: 'app-this-age',
  templateUrl: './this-age.component.html',
  styleUrls: ['./this-age.component.css']
})
export class ThisAgeComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService) {
  }

  udate: string = "";

  sum: number = 0;
  sum2: number = 0;

  money: Object;


  clickRow(udate) {
    this.router.navigate(['/ThatYear'], { queryParams: { udate: udate } });
  }
  ngOnInit(): void {

    document.getElementById("pnLoading").style.display = "block";

    this.http.getThisAge(this.udate).subscribe(res => {

      if (res.code === 200) {
        this.money = res.money;

        res.money.map((mn, i) => {
          this.sum += parseInt(mn.sum);
          this.sum2 += parseInt(mn.sum2);
        });
      }
      else if (res.code == 401) {
        this.http.logout();
      }
      else {
        alert('系統錯誤');
      }

      document.getElementById("pnLoading").style.display = "none";

    },
      err => {
        alert(err.error)
        document.getElementById("pnLoading").style.display = "none";
      });

  }

}
