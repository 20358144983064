<h4><b>設定</b></h4><br>


<div class="card">
    <div class="card-header">捷徑
        <input type="submit" class="btn btn-secondary btn-sm float-right" value="增加項目" (click)="addRow()">
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-striped table-sm">
                <thead>
                    <tr class="table-warning">
                        <th>項目</th>
                        <th>名稱</th>
                        <th>主題</th>
                        <th>價錢</th>
                        <th>時間</th>
                        <th>備註</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sc of shortcut_list; let i = index">
                        <td>{{i+1}}</td>
                        <td><input type="text" [(ngModel)]="sc.name" class="form-control  form-control-sm"></td>
                        <td>
                            <select [(ngModel)]="sc.subject" class="form-control  form-control-sm"
                                (ngModelChange)="getSelectedValue(sc,$event)">
                                <option>食野</option>
                                <option>搭車</option>
                                <option>買野</option>
                                <option>其他</option>
                                <option>奉獻</option>
                                <option>家用</option>
                                <option>旅行</option>
                                <option>學費</option>
                            </select>
                        </td>
                        <td><input type="number" [(ngModel)]="sc.price" class="form-control  form-control-sm"></td>
                        <td><input type="time" [(ngModel)]="sc.utime" class="form-control  form-control-sm"></td>
                        <td><input type="text" [(ngModel)]="sc.remark" class="form-control  form-control-sm"></td>
                        <td>
                            <img src="/assets/Images/baseline_arrow_upward_black_18dp.png" (click)="upRow(i)"
                                *ngIf="i!=0" />
                            <img src="/assets/Images/baseline_arrow_downward_black_18dp.png" (click)="downRow(i)"
                                *ngIf="shortcut_list.length!=(i+1)" />
                            <img src="/assets/Images/baseline_close_black_18dp.png" (click)="deleteRow(i)" />

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</div>
<br />

<div class="card">
    <div class="card-header">
        其他
    </div>
    <div class="card-body">

        <div class="form-group col-md-4">
            <label for="exampleInputEmail1">每月預算</label>
            <input type="number" class="form-control" [(ngModel)]="budget" min="0" max="99999" />
        </div>

    </div>
</div>


<br />

<input type="submit" class="btn btn-primary float-right" value="遞交" (click)="submit()">