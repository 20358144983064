import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThatDayComponent } from './that-day/that-day.component';
import { SummaryComponent } from './summary/summary.component';
import { ThisWeekComponent } from './this-week/this-week.component';
import { ThatMonthComponent } from './that-month/that-month.component';
import { ThatYearComponent } from './that-year/that-year.component';
import { ThisAgeComponent } from './this-age/this-age.component';
import { AddMoneyComponent } from './add-money/add-money.component';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { SettingComponent } from './setting/setting.component';
import { SearchRemarkComponent } from './search-remark/search-remark.component';
import { SearchPriceComponent } from './search-price/search-price.component';



@NgModule({
  declarations: [
    AppComponent,
    ThatDayComponent,
    SummaryComponent,
    ThisWeekComponent,
    ThatMonthComponent,
    ThatYearComponent,
    ThisAgeComponent,
    AddMoneyComponent,
    LoginComponent,
    SettingComponent,
    SearchRemarkComponent,
    SearchPriceComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
