import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  API_URL: String = "https://giantdaniel.net/money/";
  header = new HttpHeaders().set("access_token", localStorage.getItem("access_token"));


  constructor(private http: HttpClient) {
  }

  getBeer() {

    return this.http.get('https://api.openbrewerydb.org/breweries');
  }

  getSummary() {
    return this.http.get<any>(this.API_URL + 'GetSummary/daniel', { headers: this.header });
  }

  getRemark(remark) {
    let login_name = localStorage.getItem('login_name');
    return this.http.get<any>(this.API_URL + 'GetListWithSumByRemark/' + login_name + '/?remark=' + remark, { headers: this.header });
  }

  getPrice(price) {
    let login_name = localStorage.getItem('login_name');
    return this.http.get<any>(this.API_URL + 'GetListWithSumByPrice/' + login_name + '/?price=' + price, { headers: this.header });
  }

  getThatDay(udate) {
    let login_name = localStorage.getItem('login_name');
    return this.http.get<any>(this.API_URL + 'GetThatDayListWithSum/' + login_name + '/' + udate, { headers: this.header });
  }

  getThisWeek() {
    let login_name = localStorage.getItem('login_name');
    return this.http.get<any>(this.API_URL + 'GetThisWeekListWithSum/' + login_name + '/', { headers: this.header });
  }

  getThatMonth(udate) {
    let login_name = localStorage.getItem('login_name');
    return this.http.get<any>(this.API_URL + 'GetThatMonthListWithSum/' + login_name + '/' + udate, { headers: this.header });
  }

  getThatYear(udate) {
    let login_name = localStorage.getItem('login_name');
    return this.http.get<any>(this.API_URL + 'GetThatYearListWithSum/' + login_name + '/' + udate, { headers: this.header });
  }

  getThatYear2() {
    let login_name = localStorage.getItem('login_name');
    return this.http.get<any>(this.API_URL + 'GetThatYear2ListWithSum/' + login_name + '/', { headers: this.header });
  }

  getThisAge(udate) {
    let login_name = localStorage.getItem('login_name');
    return this.http.get<any>(this.API_URL + 'GetThisAgeListWithSum/' + login_name, { headers: this.header });
  }

  getMoney(money_id) {

    document.getElementById("pnLoading").style.display = "block";

    return this.http.get<any>(this.API_URL + 'GetMoney/' + money_id, { headers: this.header });
  }


  getShortcutList() {
    let login_name = localStorage.getItem('login_name');
    return this.http.get<any>(this.API_URL + 'GetShortcutList/' + login_name, { headers: this.header });
  }

  addUpdateMoney(money_id, subject, price, udate, utime, remark) {

    if (money_id == null) {

      let login_name = localStorage.getItem('login_name');
      let data = { subject: subject, price: price, udate: udate, utime: utime, remark: remark, cname: login_name };
      return this.http.post<any>(this.API_URL + 'AddMoney', data, { headers: this.header });
    }
    else {
      let data = { money_id: money_id, subject: subject, price: price, udate: udate, utime: utime, remark: remark };
      return this.http.post<any>(this.API_URL + 'EditMoney', data, { headers: this.header });
    }
  }

  deleteMoney(money_id) {

    let data = { money_id: money_id };
    return this.http.post<any>(this.API_URL + 'DeleteMoney', data, { headers: this.header });

  }

  saveShortcut(json) {
    let login_name = localStorage.getItem('login_name');
    let data = { cname: login_name, json: JSON.stringify(json) };
    return this.http.post<any>(this.API_URL + 'SaveShortcut', data, { headers: this.header });

  }

  updateBudget(budget) {
    let login_name = localStorage.getItem('login_name');
    let data = { login_name: login_name, budget: budget };
    return this.http.post<any>(this.API_URL + 'UpdateBudget', data, { headers: this.header });
  }

  checkLogin(login_name, password) {
    let data = { login_name: login_name, password: password };
    return this.http.post<any>(this.API_URL + 'CheckLogin', data);
  }


  logout() {
    localStorage.removeItem('login_name');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    window.location.href = '/Login';
  }

}
