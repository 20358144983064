<h4><b>本世紀</b></h4><br>

<table class="table table-striped table-sm">
    <tbody>
        <tr *ngFor="let mn of money" (click)="clickRow(mn.udate)">
            <td width="55%">{{mn.udate}}</td>
            <td width="10%" class="text-success">(${{mn.sum2}})</td>
            <td width="35%">${{mn.sum}}</td>
        </tr>
        <tr class="table-warning">
            <td><b class="float-right">總共:</b></td>
            <td><b class="text-success"> (${{sum2}})</b> </td>
            <td><b> ${{sum}} </b> </td>
        </tr>
    </tbody>
</table>