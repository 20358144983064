import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpService } from '../http.service';
import { NgbModule, NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

;

@Component({
  selector: 'app-add-money',
  templateUrl: './add-money.component.html',
  styleUrls: ['./add-money.component.css']
})
export class AddMoneyComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService, private calendar: NgbCalendar, private ngbDateParserFormatter: NgbDateParserFormatter) {

  }

  arr: Object;
  udate: NgbDateStruct;
  subject: string = "-1";
  price: number;
  utime: string = moment().format("HH:mm");
  remark: string = "";
  errStr: string[] = [];
  money_id: number;

  getSelectedValue(e) {
    this.subject = e;
  }

  clickShortcut(obj) {
    if (obj.subject != null) {
      this.subject = obj.subject;
    }

    if (obj.price != null) {
      this.price = obj.price;
    }

    if (obj.utime != null) {
      this.utime = obj.utime;
    }

    if (obj.remark != null) {
      this.remark = obj.remark;
    }
  }

  delete() {

    if (confirm('你是否確定要刪除?')) {

      document.getElementById("pnLoading").style.display = "block";

      this.http.deleteMoney(this.money_id)
        .subscribe(res => {

          if (res.code === 200 && res.result === 1) {
            alert('成功');
            this.router.navigate(['/ThatDay'], { queryParams: { udate: this.ngbDateParserFormatter.format(this.udate) } });
          }
          else if (res.code == 401) {
            this.http.logout();
          }
          else {
            alert('系統錯誤');
          }

          document.getElementById("pnLoading").style.display = "none";
        },
          err => {
            alert(err.error)
            document.getElementById("pnLoading").style.display = "none";
          }
        );
    }
  }

  ngOnInit(): void {

    this.udate = this.calendar.getToday();

    this.route.queryParams.subscribe((value) => {
      if (value['money_id'] != null) {
        this.money_id = value['money_id'];

        document.getElementById("pnLoading").style.display = "block";

        this.http.getMoney(this.money_id).subscribe(res => {

          console.log(res);

          if (res.code === 200) {
            let data = res.data[0];
            this.subject = data.subject;
            this.price = data.price;
            this.udate = this.ngbDateParserFormatter.parse(data.udate);
            this.utime = data.utime;
            this.remark = data.remark;
          }
          else if (res.code == 401) {
            this.http.logout();
          }
          else {
            alert('系統錯誤');
          }

          document.getElementById("pnLoading").style.display = "none";

        },
          err => {
            alert(err.error)
            document.getElementById("pnLoading").style.display = "none";
          });

        return;
      }
    });

    this.arr = JSON.parse(localStorage.getItem("scList"));

    // this.http.getShortcutList()
    //   .subscribe(res => {

    //     if (res.code === 200) {
    //       this.arr = res.arr;
    //     }
    //     else if (res.code == 401) {
    //       this.http.logout();
    //     }
    //     else {
    //       alert('系統錯誤');
    //     }

    //     document.getElementById("pnLoading").style.display = "none";

    //   },
    //     err => {
    //       alert(err.error)
    //       document.getElementById("pnLoading").style.display = "none";
    //     });


  }

  submit() {
    let errStr: string[] = [];

    console.log(this.udate);

    if (this.subject == "-1") {
      errStr.push("請選擇主題!");
    }

    if (!this.price) {
      errStr.push("請輸入價錢!");
    }

    if (!this.udate) {
      errStr.push("請輸入日期!");
    }
    else {
      var date = moment(this.ngbDateParserFormatter.format(this.udate));

      if (!date.isValid()) {
        errStr.push("日期格式錯誤!");
      }
    }

    if (errStr.length > 0) {
      window.scroll(0, 0);
      this.errStr = errStr;
      return;
    }

    document.getElementById("pnLoading").style.display = "block";

    this.http.addUpdateMoney(this.money_id, this.subject, this.price, this.ngbDateParserFormatter.format(this.udate), this.utime, this.remark)
      .subscribe(res => {

        if (res.code === 200 && res.result === 1) {
          alert('成功');
          this.router.navigate(['/ThatDay'], { queryParams: { udate: this.ngbDateParserFormatter.format(this.udate) } });
        }
        else if (res.code == 401) {
          this.http.logout();
        }
        else {
          alert('系統錯誤');
        }

        document.getElementById("pnLoading").style.display = "none";
      },
        err => {
          alert(err.error)
          document.getElementById("pnLoading").style.display = "none";
        }
      );
  }
}

