import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { HttpService } from '../http.service';
import * as moment from 'moment';

@Component({
  selector: 'app-search-remark',
  templateUrl: './search-remark.component.html',
  styleUrls: ['./search-remark.component.css']
})
export class SearchRemarkComponent implements OnInit {

  remark: String;

  sum: number = 0;

  money: Object;

  clickRow(money_id) {
    this.router.navigate(['/AddMoney'], { queryParams: { money_id: money_id } });
  }

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((value) => {
      this.remark = value['remark'];

      console.log(this.remark);

      document.getElementById("pnLoading").style.display = "block";

      this.http.getRemark(this.remark).subscribe(res => {

        if (res.code === 200) {
          this.money = res.money;

          res.money.map((mn, i) => {
            this.sum += parseInt(mn.price);
          });
        }
        else if (res.code == 401) {
          this.http.logout();
        }
        else {
          alert('系統錯誤');
        }

        document.getElementById("pnLoading").style.display = "none";

      },
        err => {
          alert(err.error)
          document.getElementById("pnLoading").style.display = "none";
        });
    });

  }

}
