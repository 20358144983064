import { Component } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { JQueryStatic } from 'jquery';
import { NgbModule, NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'daniel-money-angular';

  is_logged_in = false;

  udate: NgbDateStruct;

  remark: String;
  price: number;

  searchRemark() {
    if (!this.remark) {
      alert('請輸入備註!')
      return;
    }

    window.location.href = "/SearchRemark/?remark=" + this.remark;
  }

  searchPrice() {
    if (!this.price) {
      alert('請輸入價錢!')
      return;
    }
    window.location.href = "/SearchPrice/?price=" + this.price;
  }

  searchDate() {
    if (!this.udate) {
      alert('請輸入日期!')
      return;
    }
    window.location.href = "/ThatDay/?udate=" + this.ngbDateParserFormatter.format(this.udate);
  }

  logout() {
    localStorage.clear();
    window.location.href = '/Login';
  }

  constructor(private route: ActivatedRoute, private router: Router, private calendar: NgbCalendar, private ngbDateParserFormatter: NgbDateParserFormatter) {

    this.udate = this.calendar.getToday();

    if (localStorage.getItem("login_name") != null) {
      this.is_logged_in = true;
    }

    if (!this.is_logged_in) {
      if (window.location.href.indexOf('Login') == - 1) {
        window.location.href = '/Login';
      }
    }
  }

}
