<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->



<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->



<nav class="navbar navbar-expand-lg navbar-light bg-light" *ngIf="is_logged_in">
  <div class="container">
    <a class="navbar-brand" href="#">支出表</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="mr-auto navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" routerLink="/ThatDay" routerLinkActive="active">本日</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/ThisWeek" routerLinkActive="active">本星期</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/ThatMonth" routerLinkActive="active">本月</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/ThatYear" routerLinkActive="active">本年</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/ThisAge" routerLinkActive="active">本世紀</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="modal" data-target="#mdDate">搜尋日期</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="modal" data-target="#mdRemark">搜尋備註</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="modal" data-target="#mdPrice">搜尋價錢</a>
        </li>

      </ul>
      <div class="navbar-nav">
        <li class="nav-item"><a class="nav-link" routerLink="/AddMoney" routerLinkActive="active">增加紀錄</a></li>
        <li class="nav-item"><a class="nav-link" routerLink="/Setting" routerLinkActive="active">設定</a></li>
        <li class="nav-item"><a class="nav-link" routerLink="/Summary" routerLinkActive="active">總覽</a></li>
        <li class="nav-item"><a class="nav-link" (click)="logout()">登出</a></li>
      </div>
    </div>
  </div>

</nav>


<div class="container">
  <br />
  <router-outlet></router-outlet>
</div>


<!-- Modal -->
<div class="modal fade" id="mdRemark" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">搜尋備註</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="exampleInputEmail1">備註</label>
          <input type="text" class="form-control" [(ngModel)]="remark" />
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">關閉</button>
        <button type="button" class="btn btn-primary" (click)="searchRemark()">搜尋</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="mdPrice" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">搜尋價錢</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="exampleInputEmail1">價錢</label>
          <input type="number" class="form-control" [(ngModel)]="price" min="0" max="99999" />
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">關閉</button>
        <button type="button" class="btn btn-primary" (click)="searchPrice()">搜尋</button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="mdDate" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">搜尋日期</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="exampleInputEmail1">日期</label>
          <div class="input-group">
            <input class="form-control" name="udate" [(ngModel)]="udate" ngbDatepicker #d="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">請選擇</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">關閉</button>
        <button type="button" class="btn btn-primary" (click)="searchDate()">搜尋</button>
      </div>
    </div>
  </div>
</div>