import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { RSA_NO_PADDING } from 'constants';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {

  shortcut_list: any[] = [];

  budget: number = 0;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService,) { }


  submit() {

    let errStr = "";

    let count = 1;

    if (!this.budget) {
      errStr += "請輸入正確的每月預算!\n";
    }

    this.shortcut_list.forEach(function (sc) {
      if (!sc.name) {
        errStr += "第" + count + "行的名稱錯誤!\n";
      }

      if (!sc.utime) {
        sc.utime = null;
      }

      if (!sc.remark) {
        sc.remark = null;
      }

      count++;

    });

    if (errStr.length > 0) {
      alert(errStr);
      return;
    }

    let self = this;

    document.getElementById("pnLoading").style.display = "block";

    let res1 = new Promise(function (resolve, reject) {
      self.http.saveShortcut(self.shortcut_list).subscribe(res => {
        resolve(res);
      })
    });

    let res2 = new Promise(function (resolve, reject) {
      self.http.updateBudget(self.budget).subscribe(res => {
        resolve(res);
      })
    });

    Promise.all([res1, res2]).then(res => {

      let result1 = JSON.parse(JSON.stringify(res[0]));
      let result2 = JSON.parse(JSON.stringify(res[1]));

      console.log(result1);
      console.log(result2);

      if (result1.code === 200 && result2.code === 200) {
        localStorage.setItem("scList", JSON.stringify(result1.scList));
        alert('成功');
        this.router.navigate(['/ThatDay']);
      }
      else if (result1.code === 401 || result2.code === 401) {
        this.http.logout();
      }
      else {
        alert('系統錯誤');
      }

      document.getElementById("pnLoading").style.display = "none";

    });


    // document.getElementById("pnLoading").style.display = "block";

    // this.http.saveShortcut(this.shortcut_list).subscribe(res => {

    //   if (res.code === 200) {
    //     this.http.updateBudget(this.budget).subscribe(data => {
    //       if (data.code === 200) {
    //         alert('成功');
    //         this.router.navigate(['/ThatDay']);
    //       }
    //       else if (res.code == 401) {
    //         this.http.logout();
    //       }
    //       else {
    //         alert('系統錯誤');
    //       }
    //     });
    //   }
    //   else if (res.code == 401) {
    //     this.http.logout();
    //   }
    //   else {
    //     alert('系統錯誤');
    //   }

    //   document.getElementById("pnLoading").style.display = "none";
    // },
    //   err => {
    //     alert(err.error)
    //     document.getElementById("pnLoading").style.display = "none";
    //   }
    // );



  }

  getSelectedValue(sc, target) {
    sc.subject = target;
  }

  addRow() {
    var obj = {} as any;
    obj.name = null;
    obj.subject = "食野";
    obj.price = null;
    obj.utime = null;
    obj.remark = null;

    this.shortcut_list.push(obj);
  }

  upRow(i) {
    [this.shortcut_list[i], this.shortcut_list[i - 1]] = [this.shortcut_list[i - 1], this.shortcut_list[i]];
  }

  downRow(i) {
    [this.shortcut_list[i], this.shortcut_list[i + 1]] = [this.shortcut_list[i + 1], this.shortcut_list[i]];
  }

  deleteRow(i) {
    this.shortcut_list.splice(i, 1);
  }

  ngOnInit(): void {

    document.getElementById("pnLoading").style.display = "block";

    this.http.getShortcutList().subscribe(res => {

      if (res.code == 200) {
        this.shortcut_list = res.arr;
      }

      document.getElementById("pnLoading").style.display = "none";

    });

    this.http.getSummary().subscribe(res => {

      if (res.code == 200) {
        this.budget = res.budget;
      }

      document.getElementById("pnLoading").style.display = "none";

    });


  }

}
