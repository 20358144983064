import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { $ } from 'protractor';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  budget: number = 0;
  this_month: number = 0;
  surplus: number = 0;
  average: number = 0;
  this_day: number = 0;
  hidden_average: number = 0;
  remark_list: Object = [];
  brews: Object;

  clickRemark(remark) {
    this.router.navigate(['/SearchRemark'], { queryParams: { remark: remark } });
  }

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService) { }

  ngOnInit(): void {

    document.getElementById("pnLoading").style.display = "block";

    this.http.getSummary().subscribe(res => {

      if (res.code === 200) {
        this.budget = res.budget;
        this.this_month = res.this_month;
        this.surplus = res.surplus;
        this.average = res.average;
        this.this_day = res.this_day;
        this.hidden_average = res.hidden_average;
        this.remark_list = res.remark_list;
      }
      else if (res.code == 401) {
        this.http.logout();
      }
      else {
        alert('系統錯誤');
      }

      document.getElementById("pnLoading").style.display = "none";
    },
      err => {
        alert(err.error)
        document.getElementById("pnLoading").style.display = "none";
      });
  }

}
