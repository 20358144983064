<h4><b>搜尋價錢 - {{price}}</b></h4><br>

<table class="table table-striped table-sm">
    <tbody>
        <tr *ngFor="let mn of money" (click)="clickRow(mn.money_id)">
            <td>{{mn.udate}}</td>
            <td>{{mn.utime}}</td>
            <td>{{mn.subject}} <span *ngIf="mn.remark!=null">({{mn.remark}})</span></td>
            <td>${{mn.price}}</td>
        </tr>
        <tr class="table-warning">
            <td></td>
            <td></td>
            <td><b class="float-right">總共:</b></td>
            <td><b> ${{sum}}</b> </td>
        </tr>
    </tbody>
</table>