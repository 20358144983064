import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpService } from '../http.service';

@Component({
  selector: 'app-this-week',
  templateUrl: './this-week.component.html',
  styleUrls: ['./this-week.component.css']
})
export class ThisWeekComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpService) { }

  udate: string = "";

  sum: number = 0;

  money: Object;

  clickRow(udate) {
    this.router.navigate(['/ThatDay'], { queryParams: { udate: udate } });
  }

  ngOnInit(): void {

    document.getElementById("pnLoading").style.display = "block";

    this.http.getThisWeek().subscribe(res => {

      if (res.code === 200) {
        this.money = res.money;

        res.money.map((mn, i) => {
          this.sum += parseInt(mn.sum);
        });
      }
      else if (res.code == 401) {
        this.http.logout();
      }
      else {
        alert('系統錯誤');
      }

      document.getElementById("pnLoading").style.display = "none";

    },
      err => {
        alert(err.error)
        document.getElementById("pnLoading").style.display = "none";
      });

  }

}
