<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <div class="card">
            <div class="card-header">登入</div>
            <div class="card-body">
                <div class="form-group"><label>登入名稱</label>
                    <input type="text" class="form-control" name="login_name" [(ngModel)]="login_name">
                </div>
                <div class="form-group"><label>密碼</label>
                    <input type="password" name="password" class="form-control" [(ngModel)]="password">
                </div>
                <input type="submit" class="btn btn-primary" value="遞交" style="width: 100%;" (click)="submit()">
            </div>
        </div>
    </div>
</div>